<template>
  <v-container fill-height>
    <SnackBar
      :snackMsg="snackMsg"
      :snackOn="snackOn"
      :snackTop="true"
      @close="snackOn = false"
    />

    <v-row
      align="center"
      justify="center"
    >
      <v-col
        xs="12"
        sm="8"
        md="4"
        lg="6"
        xl="4"
      >
        <v-fade-transition hide-on-leave>
          <v-skeleton-loader
            v-if="loading"
            height="301"
            class="mx-auto"
            type="image"
          />
        </v-fade-transition>

        <v-fade-transition hide-on-leave>
          <v-card
            v-if="!loading"
            outlined
          >
            <v-row justify="center">
              <v-card-title
                v-if="isInvalidToken"
                class="text-center"
                v-text="$t('verify.invalidToken')"
              />

              <v-card-title
                v-else
                class="text-center"
                v-text="$t('verify.newAccountPwdTitle')"
              />
            </v-row>

            <v-divider />

            <v-card-text>
              <div v-if="isInvalidToken">
                <v-container>
                  <v-row justify="center">
                    <div v-text="`${$t('verify.invalidToken')} ${$t('verify.goToSignupToken')}`"></div>
                  </v-row>
                </v-container>

                <v-divider class="my-2" />

                <v-card-actions>
                  <v-row justify="center">
                    <v-btn
                      color="primary"
                      to="/signup"
                      v-text="$t('verify.genNewToken')"
                    />
                  </v-row>
                </v-card-actions>
              </div>

              <div v-else>
                <v-form ref="form">
                  <v-text-field
                    :disabled="isInvalidToken"
                    v-model="password"
                    :append-icon="mdiLock"
                    :rules="[v => v.length >= 6 || $t('validation.invalidPwd')]"
                    :label="$t('verify.pwdLabel')"
                    validate-on-blur
                    name="password"
                    type="password"
                    outlined
                    dense
                  />

                  <v-text-field
                    :disabled="isInvalidToken"
                    v-model="passwordConfirm"
                    :append-icon="mdiLock"
                    :rules="[v => v === password || $t('validation.pwdsDontMatch')]"
                    :label="$t('verify.pwdConfirmLabel')"
                    validate-on-blur
                    name="password"
                    type="password"
                    outlined
                    dense
                  />

                  <v-card-text>
                    <span
                      class="text-justify"
                      v-html="$t('signup_page.acceptTerms')"
                    ></span>
                  </v-card-text>
                  
                  <v-card-actions>
                    <v-row justify="center">
                      <v-btn
                        large
                        color="primary"
                        :disabled="isInvalidToken"
                        :loading="loadingBtn"
                        @click="submit"
                      >
                        {{ $t('verify.signup') }}
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </v-fade-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { SnackBar } from '@components/App'
import { mapActions } from 'vuex'
import API from '@api'
import { mdiLock } from '@mdi/js'

export default {
  components: {
    SnackBar
  },

  async created () {
    const {token} = this.$route.query
    this.token = token
    if (token) {
      try {
        const data = await API().get('user/validate/email', {
          params: {
            token
          }
        })
          
        if (data) {

          this.fullName = data.fullName
          const [firstName] = this.fullName.split(' ')
          this.firstName = firstName
          this.email = data.email

        } else {

          this.snackMsg = 'expiredEmailToken'
          this.snackOn =  true
          this.isInvalidToken = true

        }

      } catch (err) {

        this.snackMsg = 'errEmailToken'
        this.snackOn = true

      }

    } else {

      this.$router.push({name: 'open.signup'})
      
    }

    this.loading = false
  },

  data () {
    return {
      mdiLock,
      
      snackMsg: '',
      snackOn: false,
      loading: true,
      loadingBtn: false,
      isInvalidToken: false,
      firstName: '',
      fullName: '',
      email: '',
      password: '',
      passwordConfirm: ''
    }
  },

  methods: {
    ...mapActions({
      setSession: 'setSession'
    }),

    async submit () {
      this.loadingBtn = true

      if (!navigator.cookieEnabled) {
        this.snackMsg = 'noCookies'
        this.snackOn = true
      } else if (this.$refs.form.validate()) {
        const fullName = this.fullName
        const email = this.email
        const password = this.password
        const userData = {
          fullName,
          password,
          email 
        }

        try {
          
          const { user, profile, authorizationToken } = await API().post('/user', userData)
          
          await API().delete('/user/validate/delete', {
            params: {
              token: this.token
            }
          })
          
          this.setSession({user, profile, authorizationToken})
          this.$socket.onlineSession.connect()

          this.$router.push({name: 'profile.edit', query: { new: true } })

        } catch(err) {

          this.snackMsg = err.response.data.errMsg
          this.snackOn = true

        } finally {

          this.loadingBtn = false
        
        }
        
      }
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 200% !important;
  font-weight: 300; 
  margin: 0;
}

a {
  text-decoration: none;
}
</style>